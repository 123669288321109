

import { firestore } from '@/firebase';
import { USER_GETTERS } from '@/store/user/getters';
import { format } from 'date-fns';
import { fr, nlBE } from 'date-fns/locale';
import { collection, collectionGroup, getDoc, getDocs, orderBy, query, where, doc, Timestamp } from 'firebase/firestore';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ProgramWebinarsCard extends Vue {
    @Prop({ required: true }) userId!: string;

    open = false;
    webinars = [] as any[];

    get user() {
        return this.$store.getters[USER_GETTERS.ACTING_USER];
    }

    async mounted() {
        const intermediate = [];
        const result = await getDocs(query(collection(firestore, 'event-attendees'), where('user', '==', this.userId)));
        for (const atteendee of result.docs) {
            const webinar = await getDoc(doc(firestore, 'webinars', atteendee.data().webinarId));
            const coach = await getDoc(doc(firestore, 'users', webinar.data()!.coach));
            intermediate.push({
                present: atteendee.data().present,
                id: webinar.id,
                title: webinar.data()!.title,
                url: webinar.data()!.teamsUrl,
                coach: `${coach.data()!.firstname} ${coach.data()!.lastname}`,
                date: format((webinar.data()!.start as Timestamp).toDate(), 'dd MMMM yyyy hh:mm', {
                    locale: this.user.language === 'nl-be' ? nlBE : fr
                }),
            })
        }
        this.webinars = intermediate;
    }
}
